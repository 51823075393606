<template>
  <div class="discussion" style="padding: 7px">
    <v-row>
      <p class="title">{{ $t("Discussion Requests") }}</p>
    </v-row>
    <div class="preloader" style="text-align: center" v-if="loading">
      <img
        src="../../assets/img/preloader.gif"
        id="preloader-custom"
        alt="preloader"
        class
      />
    </div>
    <v-row class="item" v-for="(post, postIndex) in posts" :key="postIndex">
      <v-col cols="9" sm="9">
        <v-row>
          <v-col sm="2" md="2" lg="2">
            <v-responsive
              v-if="post.post_user_logo"
              style="display: inline-block; margin: auto"
              class="rounded-circle"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                :src="post.post_user_logo"
              />
            </v-responsive>

            <v-responsive
              v-else
              class="rounded-circle"
              style="display: inline-block"
            >
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                src="../../assets/student.png"
              />
            </v-responsive>
          </v-col>
          <v-col sm="8" md="8" lg="8" style="margin-left: -6rem">
            <div class="person-info text-left">
              <p class="main-color name">
                <strong>{{ post.post_user }}</strong>
              </p>
              <p class="time">{{ post.time }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" sm="3" class="text-right" v-if="post.can_edit">
        <v-icon
          class="main-color editAction"
          style="cursor: pointer"
          @click="openEditMode(post.id, postIndex)"
          >edit</v-icon
        >
        <v-icon
          class="main-color deleteAction"
          style="cursor: pointer"
          @click="deletePostDialogOpen(postIndex)"
          >delete</v-icon
        >
        <v-icon
          color="#7297FF"
          style="cursor: pointer"
          @click="approve(post.id)"
          >check_circle</v-icon
        >
        <!-- <v-icon class="main-color reaction" large>thumb_up</v-icon>
        <span> Like</span>-->
      </v-col>
      <v-col
        cols="12"
        class="addTopic"
        v-if="edited && post.id == postIdEdited"
      >
        <v-form ref="form" v-model="valid">
          <v-text-field
            solo
            v-model="editedPost.title"
            :placeholder="$i18n.t('New Topic')"
            :rules="[validationRules.required, validationRules.maxLength225]"
          ></v-text-field>
          <!-- <p v-if="postTitleError != ''" class="post-error">
              {{ postTitleError }}
          </p>-->
          <v-textarea
            v-if="editedPost.title != ''"
            solo
            v-model="editedPost.body"
            :placeholder="$i18n.t('Details')"
            :rules="[validationRules.required]"
            style="margin-top: 3px"
          ></v-textarea>
          <!-- <p v-if="postBodyError != ''" class="post-error">
              {{ postBodyError }}
          </p>-->
          <vue-tags-input
            v-if="editedPost.title != ''"
            v-model="tag"
            :autocomplete-items="filteredItems"
            style="
              width: 100%;
              max-width: unset;
              margin-bottom: 20px;
              margin-top: 3px;
            "
            :tags="editedPost.tags"
            @tags-changed="newTags => (editedPost.tags = newTags)"
          />

          <div class="attachments">
            <v-row class="images" v-if="post.attachments.image">
              <v-col
                cols="6"
                sm="3"
                v-for="(image, index) in post.attachments.image"
                :key="index"
              >
                <div class="delete-attatch text-right">
                  <v-icon
                    class="delete-attatch-icon"
                    @click.prevent="
                      openConfirmDialog(index, image.id, 'image', postIndex)
                    "
                    color="red"
                    >close</v-icon
                  >
                </div>
                <a :href="image.url" target="_blank" class="imgContainer">
                  <img :src="image.url" class="postImg" />
                </a>
              </v-col>
            </v-row>
            <v-row class="other">
              <p style="width: 100%">
                <!-- <strong>Other attachments:</strong> -->
              </p>
              <!-- video -->
              <div
                class="video"
                v-if="
                  post.attachments.video && post.attachments.video.length > 0
                "
              >
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(video, index) in post.attachments.video"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, video.id, 'video', postIndex)
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>
                  <a
                    :href="file.url"
                    target="_blank"
                    v-for="(file, index) in post.attachments.video"
                    :key="index"
                    >{{ file.name }}</a
                  >
                </v-col>
              </div>

              <!-- document -->
              <div
                class="document"
                v-if="
                  post.attachments.document &&
                    post.attachments.document.length > 0
                "
              >
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(file, index) in post.attachments.document"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, file.id, 'document', postIndex)
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>

                  <a :href="file.url" target="_blank">
                    <!-- <v-icon class="main-color">description</v-icon> -->
                    <span>{{ file.name }}</span>
                  </a>
                </v-col>
              </div>

              <!-- audio -->
              <div
                class="audio"
                v-if="
                  post.attachments.audio && post.attachments.audio.length > 0
                "
              >
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(audio, index) in post.attachments.audio"
                  :key="index"
                >
                  <div class="delete-attatch text-right">
                    <v-icon
                      class="delete-attatch-icon"
                      @click.prevent="
                        openConfirmDialog(index, audio.id, 'audio', postIndex)
                      "
                      color="red"
                      >close</v-icon
                    >
                  </div>

                  <v-icon class="main-color">audiotrack</v-icon>
                  <a
                    :href="file.url"
                    target="_blank"
                    v-for="(file, index) in post.attachments.audio"
                    :key="index"
                    >{{ file.name }}</a
                  >
                </v-col>
              </div>
            </v-row>
          </div>
          <!-- end attachment -->
          <!-- inputs send and attatchment -->
          <p v-if="fileNames != ''">{{ fileNames }}</p>
          <v-row>
            <v-col sm="10">
              <input
                type="file"
                class="myFileBrowser"
                multiple
                ref="attachment"
                @change="readFiles"
              />
              <v-icon class="main-color text-right" style="cursor: pointer"
                >attach_file</v-icon
              >
            </v-col>
            <v-col sm="1" class="text-right">
              <v-btn
                title="cancel"
                @click.prevent="cancel"
                class="main-color modal-btn-cancel cancelBtn"
                style="cursor: pointer"
                >Cancel</v-btn
              >
            </v-col>
            <v-col sm="1">
              <v-btn
                class="main-color modal-btn-save saveBtn"
                style="cursor: pointer"
                title="Save"
                @click.prevent="savePost"
                >Edit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- Attatchment -->
      </v-col>
      <v-col cols="12" v-if="!edited || postIdEdited != post.id">
        <div class="topicContent">
          <p class="type">
            <strong>{{ post.title }}</strong>
          </p>
          <!-- <p class="topic">
            {{ post.body }}
          </p>-->
          <chat-line :line="post.body" class="topic"></chat-line>
          <div class="attachments">
            <v-row class="images" v-if="post.attachments.image">
              <v-col
                cols="6"
                sm="3"
                v-for="(image, index) in post.attachments.image"
                :key="index"
              >
                <a :href="image.url" target="_blank" class="imgContainer">
                  <img :src="image.url" class="postImg" />
                </a>
              </v-col>
            </v-row>
            <v-row class="other">
              <p style="width: 100%">
                <!-- <strong>Other attachments:</strong> -->
              </p>
              <div class="video" v-if="post.attachments.video.length > 0">
                <v-icon class="main-color">movie</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.video"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
              <div class="document" v-if="post.attachments.document.length > 0">
                <v-icon class="main-color">description</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.document"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
              <div class="audio" v-if="post.attachments.audio.length > 0">
                <v-icon class="main-color">audiotrack</v-icon>
                <a
                  :href="file.url"
                  target="_blank"
                  v-for="(file, index) in post.attachments.audio"
                  :key="index"
                  >{{ file.name }}</a
                >
              </div>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>
          {{ $t("Are you sure you want to permanently remove this") }}
          {{ type }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn2"
            @click="confirmDialog = false"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            @click="deleteItem(deletedItem, indexItem, type, postIndex)"
            class="modal-btn-save yesBtn2"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deletePostDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to permanently remove this post?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="deletePostDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deletePost"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  components: {
    VueTagsInput
  },
  data() {
    return {
      tag: "",
      tags: [],
      fileNames: "",
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      postIdEdited: null,
      valid: false,
      edited: false,
      loading: false,
      posts: [],
      validation_errors: [],
      deletePostDialog: false,
      deletedPost: { postIndex: "" },
      postIndex: "",
      editedPost: {
        title: "",
        body: "",
        attachments: [],
        tags: [],
        new_attachments: []
      },
      groupData: [],
      indexItem: "",
      confirmDialog: false,
      attatchType: "",
      type: ""
    };
  },
  computed: {
    filteredItems() {
      return this.groupData.tagsAutoComplete.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    }
  },
  mixins: [validationMixin],
  methods: {
    openEditMode(postid, postIndex) {
      this.postIdEdited = postid;
      this.edited = true;
      this.editedPost.id = this.posts[postIndex].id;
      this.editedPost.title = this.posts[postIndex].title;
      this.editedPost.body = this.posts[postIndex].body;
      this.editedPost.tags = this.posts[postIndex].tags;
      this.postIndex = postIndex;
      this.editedPost.attachments = [];
    },
    savePost() {
      if (!this.valid) {
        this.validate();
      } else {
        const formData = new FormData();

        if (this.editedPost.new_attachments.length > 0) {
          this.editedPost.new_attachments.forEach(file => {
            formData.append("file[]", file);
          });
        }

        formData.append("id", this.editedPost.id);
        formData.append("approved", this.editedPost.approved);
        formData.append("title", this.editedPost.title);
        formData.append("body", this.editedPost.body);
        formData.append(
          "discussion_room_id",
          this.$router.currentRoute.params.roomId
        );
        console.log(this.editedPost.tags);
        if (this.editedPost.tags.length > 0 && this.editedPost.tags != "") {
          this.editedPost.tags.forEach(tag => {
            formData.append("tags[]", tag.text);
          });
        }
        axios
          .post(
            this.getApiUrl +
              "/discussionboard/posts/update/" +
              this.editedPost.id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              // this.resetPostFields();
              this.edited = false;
              this.postIdEdited = null;
              location.reload();
              // this.getPosts();
              this.getPosts();
              this.$refs.form.resetValidation();
            }
          });
      }
    },
    cancel() {
      location.reload();
    },

    openConfirmDialog(index, imageId, attatchType, postIndex) {
      this.confirmDialog = true;
      this.deletedItem = imageId;
      this.indexItem = index;
      this.type = attatchType;
      this.postIndex = postIndex;
    },
    deleteItem(item, index, type, postIndex) {
      let attatchId = item;
      let attatchType = type;
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/post/deleteAttachment/" +
            attatchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            console.log(attatchType);
            if (attatchType == "image") {
              this.posts[postIndex].attachments.image.splice(index, 1);
            }
            if (attatchType == "document") {
              this.posts[postIndex].attachments.document.splice(index, 1);
            }
            if (attatchType == "video") {
              this.posts[postIndex].attachments.video.splice(index, 1);
            }
            if (attatchType == "audio") {
              this.posts[postIndex].attachments.audio.splice(index, 1);
            }
          }
        });

      this.confirmDialog = false;
    },

    getPosts() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/postsNeedApprove/" +
            this.$router.currentRoute.params.roomId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(res => {
          this.loading = false;
          this.posts = res.data.data;
          if (this.posts.length == 0) {
            this.$router.push(
              {
                path:
                  "/discussionboard/" + this.$router.currentRoute.params.roomId
              },
              () => {}
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    approve(post_id) {
      axios
        .post(this.getApiUrl + "/discussionboard/posts/approve/" + post_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Post approved successfully";
            // location.reload();
            this.getPosts();
          }
        });
    },
    readFiles() {
      var _this = this;
      // console.log(this.$refs.attachment);
      this.$refs.attachment[0].files.forEach(file => {
        if (_this.fileNames.length == 0) {
          _this.fileNames = file.name;
        } else {
          _this.fileNames = _this.fileNames + ", " + file.name;
        }
        _this.editedPost.new_attachments.push(file);
      });
    },
    deletePostDialogOpen(postIndex) {
      this.deletedPost.postIndex = postIndex;
      this.deletePostDialog = true;
    },
    deletePost() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/posts/destroy/" +
            this.posts[this.deletedPost.postIndex].id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.posts.splice(this.deletedPost.postIndex, 1);
            this.deletePostDialog = false;
          }
        });
    }
  },
  mounted() {
    this.getPosts();

    axios
      .get(
        this.getApiUrl +
          "/discussionboard/show/" +
          this.$router.currentRoute.params.roomId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        }
      )
      .then(res => {
        this.groupData = res.data.data;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.addTopic {
  padding: 20px 20px;
  background-color: #f5f6f7;
}
.post-error {
  font-size: 14px;
  color: red;
}
.reaction {
  cursor: pointer;
}
.attachments .imgContainer {
  display: block;
  height: 100%;
  position: relative;
}
.attachments .postImg {
  border: 2px solid transparent;
  // border-radius: 50px;
  width: 100%;
}
.video,
.document,
.audio {
  width: 100%;
  margin-bottom: 10px;
}
.discussion {
  .title {
    color: #7297ff;
    font-size: px !important;
    font-weight: bold !important;
  }
  .item {
    box-shadow: $box-shadow;
    padding: 10px 20px;
    margin-bottom: 1rem;
    .person-info {
      display: inline-block;
      margin-left: 1rem;
      .name {
        font-size: 16px;
        margin-bottom: 0 !important;
      }
      .time {
        margin-bottom: 0 !important;
        font-size: 12px;
      }
    }
    .topicContent {
      margin-top: 1rem;
      padding-left: 10px;
    }
    .topicInfo {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
}

.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
.delete-attatch .delete-attatch-icon {
  color: red;
  padding: 10px;
  // border: 2px solid red;
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  cursor: pointer;
}
</style>
